<template>
	<div>
		<el-button @click="createToken">新增token</el-button>
		<table border="1" style="width: 100%;">
			<tr>
				<th style="width: 25%">Token</th>
				<th style="width: 25%">创建时间</th>
				<th>操作</th>
			</tr>
			<tr v-for="(item, idx) in list" :key="idx">
				<td>{{ item.token }}</td>
				<td>{{ item.expire }}</td>
				<td>
					<el-button size="mini" @click="deleteToken(item)">删除</el-button>
				</td>
			</tr>
		</table>
	</div>
</template>

<script type="text/ecmascript-6">
import * as AppResourceApi from '../../api/moudle/appResourceApi';
export default {
	name: 'Tokens',
	components: {

	},
	data() {
		return {
			list: [],  
		};
	},
	mounted() {
		this.requsetTokenList();
	},
	methods: {
		requsetTokenList() {
			AppResourceApi.getTokens().then(
				resp => {
					this.list = resp.data;
				}, 
				reject => {
					this.$message.error(reject);
				}
			);
		},
		deleteToken(item) {
			AppResourceApi.deleteToken(item.id).then(
				resp => {
					this.$message({
						message: '删除成功',
						type: 'success'
					});
					this.requsetTokenList();
				},
				reject => {
					this.$message.error(reject);
				}
			);
		},
		createToken() {
			AppResourceApi.createToken().then(
				resp => {
					this.$message({
						message: '新建成功',
						type: 'success'
					});
					this.requsetTokenList();
				},
				reject => {
					this.$message.error(reject);
				}
			);
		}
	}
};
</script>


<style>
th {
    height: 50px;
    vertical-align: middle;
    background: #eee;
}
td {
    background: #fff;
}
th, td {
    border: 1px solid #aaa;
    text-align: center;
    padding: 3px;
}
</style>
