/**
 * Created by darren on 2018/3/15.
 */
import {getHttp, deleteHttp, postHttp} from '../basehttp';

// 获取AppGroup列表
export function getAppGroupList() {
	let url = 'api/appgroups';
	return getHttp(url);
}

export function getAppGroupDetail(appGroupId) {
	let url = `api/appgroup/${appGroupId}`;
	return getHttp(url);
}

// export function getAppGroupPlatformApp(appGroupId, platform) {
// 	let url = `api/appgroup/${appGroupId}/${platform}`;
// 	return getHttp(url);
// }

export function getAppGroupApps(appGroupId) {
	let url = `api/appgroup/${appGroupId}`;
	return getHttp(url);
}

export function updateAppGroupSetting(appGroupId, body) {
	let url = `api/appgroup/${appGroupId}/profile`;
	return postHttp(url, body);
}

export function getAppGroupByShortUrl(shortUrl) {
	let url = `api/short/${shortUrl}`;
	return getHttp(url);
}

export function deleteAppGroup(appGroupId) {
	let url = `api/appgroup/${appGroupId}`;
	return deleteHttp(url);
}

export function deleteApp(appId) {
	let url = `api/app/${appId}`;
	return deleteHttp(url);
}

// 获取应用列表
// export function getAppList() {
// 	let url = 'api/apps';
// 	return getHttp(url);
// }

// 获取应用详情
// export function getAppDetail(appId) {
// 	let url = `api/apps/${appId}`;
// 	return getHttp(url);
// }

// 获取应用的版本列表
// export function getAppVersionList(id, page) {
// 	let params = {
// 		page, 'size': 10
// 	};
// 	let url = `api/apps/${id}/versions`;
// 	return getHttp(url, params);
// }

// 获取某个应用的某个版本详情
// export function getAppVersionDetail(id, versionId) {
// 	let url = `api/apps/${id}/versions/${versionId}`;
// 	return getHttp(url);
// }
// 删除应用
// export function delectApp(teamId, appId) {
// 	let url = `api/apps/${teamId}/${appId}`;
// 	return deleteHttp(url);
// }

// 通过短链接获取应用最新版本
// export function getAppInfoByShortUrl(appShortUrl) {
// 	let url = `api/app/${appShortUrl}`;
// 	return getHttp(url);
// }

export function getAppVersions(appId) {
	let url = `api/app/${appId}/versions`;
	return getHttp(url);
}

// 发布应用
export function releaseApp(appId, versionId) {
	let url = `api/app/${appId}/release/${versionId}`;
	return postHttp(url);
}

// 删除某个版本
export function deleteAppVersion(versionId) {
	let url = `api/version/${versionId}`;
	return deleteHttp(url);
}

// 更新应用设置
export function updateAppSetting(appId, body) {
	let url = `api/apps/${appId}/profile`;
	return postHttp(url, body);
}

// 灰度版本
// export function grayVersion(teamId, id, body) {
// 	let url = `api/apps/${teamId}/${id}/grayPublish`;
// 	return postHttp(url, body);
// }

// 更新日志
// export function updateNote(teamId, id, versionId, body) {
// 	let url = `api/apps/${teamId}/${id}/${versionId}/profile`;
// 	return postHttp(url, body);
// }

// 统计下载次数
// export function downloadedCount(appId, versionId) {
// 	let url = `api/count/${appId}/${versionId}`;
// 	return getHttp(url);
// }


// Token
export function getTokens() {
	let url = 'api/tokens';
	return getHttp(url);
}

export function deleteToken(token) {
	let url = `api/token/${token}`;
	return deleteHttp(url);
}

export function createToken() {
	let url = 'api/token';
	return postHttp(url);
}